import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg
      style={{
        height: "24px",
        width: "100%",
        top: 2,
      }}
      viewBox="0 0 20 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.278 11.226v0h.006a1.146 1.146 0 01.796.313 1.03 1.03 0 01.324.753v8.643a1.03 1.03 0 01-.323.752l.346.36-.346-.36c-.104.1-.228.18-.365.233l.183.466-.183-.465a1.15 1.15 0 01-.432.079v0h-.81c-.298 0-.582-.116-.789-.317-.206-.2-.32-.47-.32-.748V12.29a1.029 1.029 0 01.32-.748 1.148 1.148 0 01.789-.317h.804zm5.666 1.701h0c.229 0 .508.141.769.381.202.186.321.437.34.7v6.79c0 .279-.114.548-.32.749l.348.358-.348-.358c-.207.201-.49.317-.79.317h-.809a1.117 1.117 0 01-.797-.313 1.03 1.03 0 01-.324-.753v0-6.805 0a1.03 1.03 0 01.324-.753 1.148 1.148 0 01.797-.313v0h.81zm-12.919 1.99l-.349-.359.35.359c.21-.205.497-.322.8-.322h.805c.303 0 .59.117.8.322l.35-.359-.35.359c.21.204.326.478.326.76v5.255c-.004.28-.12.55-.33.75l.347.36-.346-.36A1.15 1.15 0 014.63 22h-.805c-.3 0-.587-.116-.797-.318l-.346.36.346-.36a1.062 1.062 0 01-.329-.75v-5.255c0-.282.115-.556.325-.76zm18.067-5.154v.085h.5c.3 0 .587.115.797.317.208.201.325.471.329.751v9.93c-.004.28-.12.55-.33.751a1.15 1.15 0 01-.796.318h-.805c-.3 0-.587-.116-.797-.318a1.061 1.061 0 01-.329-.75V10.83c.004-.28.12-.55.33-.75a1.15 1.15 0 01.796-.318h.305zM4.228 11.638h-.3a.781.781 0 01-.431-.443.743.743 0 01-.024-.457.772.772 0 01.254-.393h0l.005-.005L9.34 5.53a.86.86 0 01.922-.092l4.93 2.517.272.139.248-.178 4.981-3.576a.843.843 0 01.57-.093c.202.039.379.149.498.305a.751.751 0 01-.096 1.019l-5.645 4.051h0l-.005.004a.806.806 0 01-.85.062h0l-.007-.003-4.86-2.484-.296-.15-.254.212-4.981 4.184a.852.852 0 01-.538.191z"
        stroke="#b4b4b4"
      />
    </svg>
  );
};

export default Icon;
