import IceChain from '../ice-chain'

const bscTestExplorer = { name: 'BscScan', url: 'https://testnet.bscscan.com' }

export const bsctest: IceChain = {
  id: 97,
  name: 'BNB Smart Chain Testnet',
  features: ['swap', 'info', 'farms', 'staking', 'locks'],
  network: 'binance',
  rpcUrls: {
    public: 'https://bsc-testnet-rpc.publicnode.com',
    default: 'https://bsc-testnet-rpc.publicnode.com',
  },
  blockExplorers: {
    default: bscTestExplorer,
    etherscan: bscTestExplorer,
  },
  nativeCurrency: {
    name: 'Test Binance Chain Native Token',
    symbol: 'BNB',
    decimals: 18,
  },
  blockInterval: 5,
  multicall: {
    address: '0x2C61a7A774CfD20510422B52444c204b5Ce588B6',
    blockCreated: 43202987,
  },
  swap: {
    factoryAddress: '0xF20321c65d129E151d1cf1c7dE2CF029e01E76cE',
    initCodeHash: '0x1250569c1ab0636e7443bef7a73dadb82f6eba5addfc0341fccc6971c29f2a40',
  },
  wrappedNative: {
    address: '0x9bdF11577B9cb7789E13D9487708Cc3f2C324DAD',
    decimals: 18,
    symbol: 'WBNB',
    name: 'Wrapped BNB',
  }
}