import React from "react";
import Link from "./Link";
import { LinkProps } from "./types";
import OpenNewIcon from "../Svg/Icons/OpenNew";

const LinkExternal: React.FC<React.PropsWithChildren<LinkProps>> = ({ children, gradient = false, ...props }) => {
  return (
    <Link external {...props}>
      {children}
      <OpenNewIcon
        style={{
          background: gradient ? "-webkit-linear-gradient(#0163E0, #08B2D2)" : "",
          WebkitBackgroundClip: gradient ? "text" : "",
          WebkitTextFillColor: gradient ? "transparent" : "",
        }}
        color={props.color ? props.color : "linear-gradient(#0163E0, #08B2D2)"}
        ml="4px"
      />
    </Link>
  );
};

export default LinkExternal;
