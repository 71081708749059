import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="122" height="120" viewBox="0 0 122 120" fill="none" {...props}>
      <path
        d="M31.5421 39C31.5421 36.2386 33.7807 34 36.5421 34H61.5888C64.3503 34 66.5888 36.2386 66.5888 39H31.5421Z"
        fill="#FDE53A"
      />
      <g filter="url(#filter0_f_74_3309)">
        <path
          d="M81.4837 40C81.4837 49.6661 78.4169 59.0052 72.8506 66.2902C67.2842 73.5752 59.5948 78.3133 51.2045 79.6282C42.8141 80.9431 34.2904 78.7459 27.2096 73.4429C20.1288 68.1399 14.97 60.0899 12.6873 50.7815L46.4369 40H81.4837Z"
          fill="#F47621"
          fillOpacity="0.4"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_74_3309"
          x="-27.3127"
          y="0"
          width="148.796"
          height="120"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="20" result="effect1_foregroundBlur_74_3309" />
        </filter>
      </defs>
    </Svg>
  );
};

export default Icon;
