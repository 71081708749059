/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Box, Button, Flex, Grid, Message, MessageText, Modal, Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import { useLocalNetworkChain } from '../../hooks/useActiveChainId'
import { useTranslation } from '@pancakeswap/localization'
import { useSwitchNetwork, useSwitchNetworkLocal } from '../../hooks/useSwitchNetwork'
import NextImage from 'next/image'
import useAuth from '../../hooks/useAuth'
import { useMenuItems } from '../Menu/hooks/useMenuItems'
import { useRouter } from 'next/router'
import { getActiveMenuItem, getActiveSubMenuItem } from '../Menu/utils'
import { useAccount, useNetwork } from 'wagmi'
import { useMemo } from 'react'
import { ChainId } from '@pancakeswap/sdk'
import Dots from '../Loader/Dots'
// import CraMascot from '/images/mascot.png'

import network from '../../style/Network.module.css'

// Where chain is not supported or page not supported
export function UnsupportedNetworkModal({ pageSupportedChains }: { pageSupportedChains: number[] }) {
  const { switchNetworkAsync, isLoading, canSwitch } = useSwitchNetwork()
  const switchNetworkLocal = useSwitchNetworkLocal()
  const { chains } = useNetwork()
  const chainId = useLocalNetworkChain() || ChainId.TBSC
  const { isConnected } = useAccount()
  const { logout } = useAuth()
  const { t } = useTranslation()
  const menuItems = useMenuItems()
  const { pathname } = useRouter()
  const { isMobile } = useMatchBreakpoints()

  const title = useMemo(() => {
    const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, pathname })
    const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })

    return activeSubMenuItem?.label || activeMenuItem?.label
  }, [menuItems, pathname])

  const supportedMainnetChains = useMemo(
    () => chains.filter((chain) => !chain.testnet && pageSupportedChains?.includes(chain.id)),
    [chains, pageSupportedChains],
  )

  return (
    <Modal
      hideCloseButton
      title={t('Check your network')}
      headerBackground="gradientCardHeader"
      width={['100%', null, null, '400px !important']}
    >
      <Grid style={{ gap: '16px' }}>
        <Box position="relative" mt={140}>
          <Flex justifyContent="center">
            <Flex position="absolute" top="-140px" zIndex={99}>
              <NextImage
                src="https://res.cloudinary.com/dazqakdmu/image/upload/v1723512727/craswap/mascot.png"
                alt="mascot"
                width={153}
                height={170}
                priority={true}
              />
            </Flex>
          </Flex>
          <Flex flexDirection="column" gap="8px" backgroundColor="#1A1A1A" borderRadius="24px" padding="12px 16px">
            <Text color="#ADB1B8" fontSize="14px" fontWeight={400}>
              {t('Currently %feature% is only supported on', {
                feature: typeof title === 'string' ? title : 'this page',
              })}{' '}
              {supportedMainnetChains?.map((c) => c.name).join(', ')}
            </Text>
            <Message variant="warning">
              <MessageText>{t('Please switch your network to continue.')}</MessageText>
            </Message>
          </Flex>
        </Box>
        {canSwitch ? (
          <Button
            isLoading={isLoading}
            rounded
            style={{
              height: '52px',
              borderRadius: '26px',
            }}
            onClick={() => {
              if (supportedMainnetChains.map((c) => c.id).includes(chainId)) {
                switchNetworkAsync(chainId)
              } else {
                switchNetworkAsync(ChainId.TBSC)
              }
            }}
          >
            {isLoading ? <Dots>{t('Switch network in wallet')}</Dots> : t('Switch network in wallet')}
          </Button>
        ) : (
          <Message variant="danger">
            <MessageText>{t('Unable to switch network. Please try it on your wallet')}</MessageText>
          </Message>
        )}
        {isConnected && (
          <div
            onClick={() =>
              logout().then(() => {
                switchNetworkLocal(ChainId.TBSC)
              })
            }
            className={network.disconnectText}
          >
            {t('Disconnect Wallet')}
          </div>
        )}
      </Grid>
    </Modal>
  )
}
