export const baseColors = {
  white: 'white',
  failure: '#EF454A',
  failure33: '#ED4B9E33',
  primary: '#FDE53A',
  primary0f: '#1FC7D40f',
  primary3D: '#1FC7D43D',
  primaryBright: '#53DEE9',
  primaryDark: '#0098A1',
  success: '#52D377',
  success19: '#31D0AA19',
  warning: '#FFB237',
  warning2D: '#ED4B9E2D',
  warning33: '#ED4B9E33',
}

export const additionalColors = {
  binance: '#F0B90B',
  overlay: '#452a7a',
  gold: '#FFC700',
  silver: '#B2B2B2',
  bronze: '#E7974D',
}

export const lightColors = {
  ...baseColors,
  ...additionalColors,
  secondary: '#FDE53A',
  secondary2: '#FDE53A',
  secondary80: '#9A6AFF80',
  background: '#08060B',
  backgroundDisabled: '#404347',
  backgroundAlt: '#1A1A1A',
  backgroundContrast: '#fff',
  backgroundAlt2: 'rgba(39, 38, 44, 0.7)',
  backgroundBody: '#141414',
  backgroundBadge: '#191F39',
  cardBorder: '#383241',
  contrast: '#FFFFFF',
  dark: '#333333',
  dropdown: '#1E1D20',
  dropdownDeep: '#100C18',
  headerBackground: '#111527',
  invertedContrast: '#111527',
  input: '#191F39',
  inputSecondary: '#262130',
  primaryDark: '#0098A1',
  tertiary: '#1b1e29',
  text: '#FBFBFB',
  gray: '#b4b4b4',
  text99: '#F4EEFF99',
  textDisabled: '#666171',
  textSubtle: '#b4b4b4',
  textSubtle2: '#596780',
  textSubtle3: '#b4b4b4',
  disabled: '#524B63',
  defaultColor: '#71757A',
  gradientCra: 'linear-gradient(180deg, #0163E0 0%, #08B2D2 100%)',
  gradientBubblegum: 'linear-gradient(139.73deg, #12131a 0%, #12131a 100%)',
  gradientInverseBubblegum: 'linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%)',
  gradientCardHeader: 'linear-gradient(166.77deg, #232734 0%, #232734 100%)',
  gradientBlue: 'linear-gradient(180deg, #00707F 0%, #19778C 100%)',
  gradientViolet: 'linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%)',
  gradientVioletAlt: 'linear-gradient(180deg, #434575 0%, #66578D 100%)',
  // gradientGold: 'linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)',
  gradientGold: '#FDE53A',
  gradientBold: 'linear-gradient(#53DEE9, #9A6AFF)',
}

export const darkColors = {
  ...baseColors,
  ...additionalColors,
  secondary: '#FDE53A',
  secondary2: '#FDE53A',
  secondary80: '#9A6AFF80',
  background: '#08060B',
  backgroundDisabled: '#404347',
  backgroundAlt: '#1A1A1A',
  backgroundContrast: '#fff',
  backgroundAlt2: 'rgba(39, 38, 44, 0.7)',
  backgroundBody: '#141414',
  backgroundBadge: '#191F39',
  cardBorder: '#383241',
  contrast: '#FFFFFF',
  dark: '#333333',
  dropdown: '#1E1D20',
  dropdownDeep: '#100C18',
  headerBackground: '#111527',
  invertedContrast: '#111527',
  input: '#191F39',
  inputSecondary: '#262130',
  primaryDark: '#0098A1',
  tertiary: '#1b1e29',
  text: '#FBFBFB',
  gray: '#b4b4b4',
  text99: '#F4EEFF99',
  textDisabled: '#666171',
  textSubtle: '#b4b4b4',
  textSubtle2: '#596780',
  textSubtle3: '#b4b4b4',
  disabled: '#524B63',
  defaultColor: '#71757A',
  gradientCra: 'linear-gradient(180deg, #0163E0 0%, #08B2D2 100%)',
  gradientBubblegum: 'linear-gradient(139.73deg, #12131a 0%, #12131a 100%)',
  gradientInverseBubblegum: 'linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%)',
  gradientCardHeader: 'linear-gradient(166.77deg, #232734 0%, #232734 100%)',
  gradientBlue: 'linear-gradient(180deg, #00707F 0%, #19778C 100%)',
  gradientViolet: 'linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%)',
  gradientVioletAlt: 'linear-gradient(180deg, #434575 0%, #66578D 100%)',
  // gradientGold: 'linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)',
  gradientGold: '#FDE53A',
  gradientBold: 'linear-gradient(#53DEE9, #9A6AFF)',
}
