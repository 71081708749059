import styled, { css } from 'styled-components';
import { Flex, Box } from '../Box';

export const SubMenuItemWrapper = styled(Flex)<{ $isMobileOnly: boolean }>`
  ${({ theme }) => theme.mediaQueries.sm} {
    ${({ $isMobileOnly }) => ($isMobileOnly ? 'display:none' : '')};
  }
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-left: 20px !important;
  padding-right: 20px !important;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-left: 20px !important;
    padding-right: 20px !important;
    width: 150px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    max-width: 1240px;
    // margin-left: 0;
    // margin-right: 0;
    // padding-left: 20px !important;
    // padding-right: 20px !important;
  }
`;
const StyledSubMenuItems = styled(Flex)`
  position: relative;
  z-index: 1;
  width: 480px;
  height: fit-content !important;
  padding: 6px;
  margin-top: 0px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  display: flex;
  gap: 16px;
  white-space: nowrap;
  scroll-behavior: smooth;
  ${({ theme }) => theme.mediaQueries.md} {
    width: auto;
    display: flex;
  }
  flex-grow: 1;
  background-color: #1a1a1a;
  border-radius: 24px;
  border: 1.5px solid #262626;
  border-bottom: 1.5px solid #262626 !important;
  box-shadow: inset 0px -2px 0px -8px rgba(133, 133, 133, 0.1);
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const maskSharedStyle = css`
  position: absolute;
  z-index: 2;
  width: 48px;
  height: 100%;
  top: 0px;
  display: flex;
  justify-content: center;
  will-change: opacity;
  pointer-events: none;
  opacity: 0;
  transition: 0.25s ease-out opacity;

  &.show {
    pointer-events: auto;
    opacity: 1;
    transition: 0.25s ease-in opacity;
  }
`;

export const LeftMaskLayer = styled.div`
  ${maskSharedStyle}
  left: 0px;
  background: linear-gradient(90deg, #27262c 29.76%, rgba(39, 38, 44, 0) 100%);
`;
export const RightMaskLayer = styled.div`
  ${maskSharedStyle}
  right: 0px;
  background: linear-gradient(270deg, #27262c 0%, rgba(39, 38, 44, 0) 87.5%);
`;

export const StyledSubMenuItemWrapper = styled(Box)`
  display: inline-block;
  vertical-align: top;
  scroll-snap-align: start;
`;

export default StyledSubMenuItems;
