import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        opacity="0.3"
        d="M4.46973 19H19.5297L11.9997 5.98999L4.46973 19ZM12.9997 18H10.9997V16H12.9997V18ZM12.9997 14H10.9997V9.99999H12.9997V14Z"
        fill="#FDE53A"
      />
      <path
        d="M1 21H23L12 2L1 21ZM4.47 19L12 5.99L19.53 19H4.47ZM11 16H13V18H11V16ZM11 10H13V14H11V10Z"
        fill="#FDE53A"
      />
    </Svg>
  );
};

export default Icon;
