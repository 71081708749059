import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <svg
    style={{
      height: "24px",
      width: "100%",
      top: 2,
    }}
    viewBox="0 0 20 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.3 21v-9m0-5H8.25c-2.77 0-2.94-4 0-4 3.15 0 4.05 4 4.05 4zm0 0h4.05c2.896 0 2.896-4 0-4-3.15 0-4.05 4-4.05 4z"
      stroke="#b4b4b4"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.3 12v7a2 2 0 01-2 2h-12a2 2 0 01-2-2v-7m17 0V9a2 2 0 00-2-2h-14a2 2 0 00-2 2v3h18z"
      stroke="#b4b4b4"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
