import {
  ModalProvider,
  // light,
  dark,
  UIKitProvider,
} from '@pancakeswap/uikit'
import { Provider } from 'react-redux'
import { SWRConfig } from 'swr'
import { LanguageProvider } from '@pancakeswap/localization'
import { fetchStatusMiddleware } from './hooks/useSWRContract'
import { Store } from '@reduxjs/toolkit'
import {
  ThemeProvider as NextThemeProvider,
  // useTheme as useNextTheme
} from 'next-themes'
import { WagmiProvider } from '@pancakeswap/wagmi'
import { client } from './utils/wagmi'
import { HistoryManagerProvider } from './contexts/HistoryContext'
import { GasPriceProvider } from './state/user/hooks'

const StyledUIKitProvider: React.FC<React.PropsWithChildren> = ({
  children,
  // ...props
}) => {
  // const { resolvedTheme } = useNextTheme()
  return (
    // <UIKitProvider theme={resolvedTheme === 'dark' ? dark : light} {...props}>
    <UIKitProvider theme={dark}>{children}</UIKitProvider>
  )
}

const Providers: React.FC<React.PropsWithChildren<{ store: Store; children: React.ReactNode }>> = ({
  children,
  store,
}) => {
  return (
    <WagmiProvider client={client}>
      <Provider store={store}>
        <GasPriceProvider>
          <NextThemeProvider>
            <StyledUIKitProvider>
              <LanguageProvider>
                <SWRConfig
                  value={{
                    use: [fetchStatusMiddleware],
                  }}
                >
                  <HistoryManagerProvider>
                    <ModalProvider>{children}</ModalProvider>
                  </HistoryManagerProvider>
                </SWRConfig>
              </LanguageProvider>
            </StyledUIKitProvider>
          </NextThemeProvider>
        </GasPriceProvider>
      </Provider>
    </WagmiProvider>
  )
}

export default Providers
