import styled from 'styled-components';
import { Flex } from '../Box';

const StyledBottomNav = styled(Flex)`
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 5px 8px;
  height: 65px;
  background-color: rgba(20, 20, 20, 1);
  border-radius: 32px 32px 0px 0px !important;
  border-top: 1px solid #f476214d !important;
  padding-bottom: env(safe-area-inset-bottom);
  html[data-useragent*='TokenPocket_iOS'] & {
    padding-bottom: 45px;
  }
  z-index: 20;
`;

export default StyledBottomNav;
