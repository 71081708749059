import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} viewBox="0 0 17 16" fill="none" {...props}>
      <g clipPath="url(#clip0_47_1720)">
        <path
          d="M12.045 6c.568 0 .864.657.522 1.082l-.05.056-4 4a.667.667 0 01-.88.055l-.063-.055-4-4-.056-.063-.036-.051-.036-.064-.011-.024-.018-.045-.021-.072-.007-.035-.007-.04-.002-.038v-.079l.003-.038.006-.04.007-.035.02-.072.019-.045.047-.088.043-.06.049-.054.062-.055.052-.036.064-.036.024-.011.044-.018.072-.022.036-.006.04-.007.038-.003L12.045 6z"
          fill={props.fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_47_1720">
          <path fill={props.fill} transform="translate(.045)" d="M0 0H16V16H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
