import { Svg, SvgProps } from '@pancakeswap/uikit'

export const SuccessIcon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <Svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask id="mask0_11_2976" maskUnits="userSpaceOnUse" x="8" y="8" width="84" height="84">
      <path
        d="M76.6375 76.6303L76.63 76.6378C73.1375 80.1396 68.9873 82.9166 64.4181 84.8093C59.8489 86.702 54.9506 87.6731 50.0049 87.6667L49.9946 87.6667C45.0489 87.673 40.1506 86.7019 35.5814 84.8092C31.0121 82.9165 26.8619 80.1395 23.3693 76.6377L23.3619 76.6303C19.8601 73.1378 17.0831 68.9877 15.1904 64.4184C13.2977 59.8492 12.3266 54.9509 12.3331 50.0052L12.333 49.9949C12.3267 45.0492 13.2978 40.1509 15.1905 35.5817C17.0833 31.0124 19.8603 26.8622 23.362 23.3696L23.362 23.3696L23.3693 23.3623C26.8619 19.8606 31.0121 17.0836 35.5814 15.1909C40.1506 13.2981 45.0489 12.327 49.9946 12.3334L50.0049 12.3333C54.9506 12.3269 59.8489 13.298 64.4181 15.1907C68.9874 17.0834 73.1375 19.8604 76.63 23.3622L76.6374 23.3696C80.1392 26.8622 82.9162 31.0124 84.8089 35.5817C86.7016 40.1509 87.6727 45.0492 87.6664 49.9949L87.6664 50.0052C87.6728 54.9509 86.7017 59.8492 84.809 64.4184C82.9163 68.9877 80.1393 73.1378 76.6375 76.6303Z"
        fill="#555555"
        stroke="white"
        strokeWidth="8"
        strokeLinejoin="round"
      />
      <mask id="path-2-inside-1_11_2976" fill="white">
        <path d="M33.333 50L45.833 62.5L70.833 37.5" />
      </mask>
      <path
        d="M37.5756 45.7574C35.2325 43.4142 31.4335 43.4142 29.0904 45.7574C26.7472 48.1005 26.7472 51.8995 29.0904 54.2426L37.5756 45.7574ZM45.833 62.5L41.5904 66.7426C43.9335 69.0858 47.7325 69.0858 50.0756 66.7426L45.833 62.5ZM75.0756 41.7426C77.4188 39.3995 77.4188 35.6005 75.0756 33.2574C72.7325 30.9142 68.9335 30.9142 66.5904 33.2574L75.0756 41.7426ZM29.0904 54.2426L41.5904 66.7426L50.0756 58.2574L37.5756 45.7574L29.0904 54.2426ZM50.0756 66.7426L75.0756 41.7426L66.5904 33.2574L41.5904 58.2574L50.0756 66.7426Z"
        fill="white"
        mask="url(#path-2-inside-1_11_2976)"
      />
    </mask>
    <g mask="url(#mask0_11_2976)">
      <path d="M0 0H100V100H0V0Z" fill="#24AE64" />
    </g>
  </Svg>
)
