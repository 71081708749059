import { useTranslation } from '@pancakeswap/localization'
import { WalletModalV2 } from '@pancakeswap/ui-wallets'
import { Button, ButtonProps, ArrowForwardIcon, Flex, FlexGap, Text } from '@pancakeswap/uikit'
import { createWallets, getDocLink } from '../config/wallet'
import { useActiveChainId } from '../hooks/useActiveChainId'
import useAuth from '../hooks/useAuth'
// @ts-ignore
// eslint-disable-next-line import/extensions
import { useActiveHandle } from '../hooks/useEagerConnect.bmp.ts'
import { useMemo, useState } from 'react'
import { useConnect } from 'wagmi'
import Trans from './Trans'

const ConnectWalletButton = ({ children, ...props }: ButtonProps) => {
  const handleActive = useActiveHandle()
  const { login } = useAuth()
  const {
    t,
    currentLanguage: { code },
  } = useTranslation()
  const { connectAsync } = useConnect()
  const { chainId } = useActiveChainId()
  const [open, setOpen] = useState(false)

  const docLink = useMemo(() => getDocLink(code), [code])

  const handleClick = () => {
    if (typeof __NEZHA_BRIDGE__ !== 'undefined') {
      handleActive()
    } else {
      setOpen(true)
    }
  }

  const ChevronRight = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.5 10.9999L16.086 10.9999L11.586 6.49994L13 5.08594L19.914 11.9999L13 18.9139L11.586 17.4999L16.086 12.9999L4.5 12.9999L4.5 10.9999Z"
        fill="white"
      />
    </svg>
  )

  const wallets = useMemo(() => createWallets(chainId, connectAsync), [chainId, connectAsync])

  return (
    <>
      <Button
        onClick={handleClick}
        {...props}
        variant="primary"
        // width={250}
        // height={56}
        startIcon
        style={{
          padding: `${props.isWeight ? '27px 16px' : '22px'} `,
          borderRadius: `${props?.rounded ? '40px' : '22px'}`,
          width: `${props.isBlock ? '100%' : 'auto'}`,
          boxShadow: '0px 16px 50px 0px rgba(244, 118, 33, 0.20)',
        }}
      >
        {children || (
          <Text
            style={{
              fontSize: '14px',
              fontWeight: '600',
              color: '#333',
            }}
          >
            Connect Wallet
          </Text>
        )}
        {props.withIcon && <ChevronRight />}
      </Button>
      <WalletModalV2
        docText={t('Learn How to Connect')}
        docLink={docLink}
        isOpen={open}
        wallets={wallets}
        login={login}
        onDismiss={() => setOpen(false)}
      />
    </>
  )
}

export default ConnectWalletButton
