import { ChainId, WETH9, ERC20Token } from '@pancakeswap/sdk'

export const bsctestTokens = {
  wbnb: WETH9[ChainId.TBSC],
  usdt: new ERC20Token(
    ChainId.TBSC,
    '0xf062b8Fa77CB33DA16330DdE88Af628428B8c3dc',
    18,
    'USDT',
    'USD Tether',
    'https://tether.to',
  ),
  cra: new ERC20Token(
    ChainId.TBSC,
    '0x0fA954e12686001F830f7ECB05D20d8bf4AbA2db',
    18,
    'CRA',
    'CRASWAP',
    'https://craswap.com',
  ),
  bnb: new ERC20Token(
    ChainId.BSC,
    '0x9bdF11577B9cb7789E13D9487708Cc3f2C324DAD',
    18,
    'BNB',
    'BNB',
    'https://www.binance.com/',
  ),
}
