import React, { SVGProps } from 'react'

export const ChartActiveIcon = ({ props }: { props?: SVGProps<any> }) => {
  return (
    <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width={48} height={48} rx={16} fill="#141414" />
      <path
        d="M23.943 13.25H25.5a.75.75 0 110 1.5H24c-2.378 0-4.086.002-5.386.176-1.279.172-2.05.5-2.62 1.069-.569.57-.896 1.34-1.068 2.619-.174 1.3-.176 3.008-.176 5.386s.002 4.086.176 5.386c.172 1.279.5 2.05 1.069 2.62.57.569 1.34.896 2.619 1.068 1.3.174 3.008.176 5.386.176s4.086-.002 5.386-.176c1.279-.172 2.05-.5 2.62-1.069.569-.57.896-1.34 1.068-2.619.174-1.3.176-3.008.176-5.386v-1.5a.75.75 0 111.5 0v1.557c0 2.309 0 4.118-.19 5.53-.194 1.444-.6 2.584-1.494 3.479-.895.895-2.035 1.3-3.48 1.494-1.411.19-3.22.19-5.529.19h-.114c-2.309 0-4.118 0-5.53-.19-1.444-.194-2.584-.6-3.479-1.494-.895-.895-1.3-2.035-1.494-3.48-.19-1.411-.19-3.22-.19-5.529v-.114c0-2.309 0-4.118.19-5.53.194-1.444.6-2.584 1.494-3.479.895-.895 2.035-1.3 3.48-1.494 1.411-.19 3.22-.19 5.529-.19z"
        fill="#FDE53A"
      />
      <path
        d="M29.48 21.424a.75.75 0 01.096 1.056l-1.829 2.195c-.328.394-.624.75-.9 1-.302.27-.68.513-1.18.513-.5 0-.879-.242-1.18-.514-.276-.25-.572-.605-.9-1l-.293-.35c-.37-.445-.599-.716-.787-.885a.768.768 0 00-.163-.122l-.007-.003-.004-.002-.01.005a.764.764 0 00-.164.122c-.187.17-.415.44-.786.885l-1.797 2.156a.75.75 0 01-1.152-.96l1.83-2.195c.327-.394.623-.75.9-1 .301-.27.68-.513 1.18-.513.5 0 .878.242 1.18.514.275.25.571.605.9 1l.292.35c.371.445.6.716.787.885.086.078.138.11.163.122l.01.005.005-.002.007-.003a.762.762 0 00.163-.122c.187-.17.415-.44.786-.885l1.797-2.156a.75.75 0 011.056-.096z"
        fill="#FDE53A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31 13.25a3.75 3.75 0 100 7.5 3.75 3.75 0 000-7.5zM28.75 17a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0z"
        fill="#FDE53A"
      />
    </svg>
  )
}
