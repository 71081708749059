import { cloneElement, ElementType, isValidElement } from 'react';
import EXTERNAL_LINK_PROPS from '../../util/externalLinkProps';
import StyledButton from './StyledButton';
import { ButtonProps, scales, variants } from './types';

const Button = <E extends ElementType = 'button'>(props: ButtonProps<E>): JSX.Element => {
  const {
    startIcon,
    endIcon,
    external,
    className,
    isLoading,
    disabled,
    children,
    rounded = false,
    isBlock,
    ...rest
  } = props;
  const internalProps = external ? EXTERNAL_LINK_PROPS : {};
  const isDisabled = isLoading || disabled;
  const classNames = className ? [className] : [];

  if (isLoading) {
    classNames.push('pancake-button--loading');
  }

  if (isDisabled && !isLoading) {
    classNames.push('pancake-button--disabled');
  }

  if (variants.PRIMARY) {
    classNames.push();
  }

  return (
    <StyledButton
      isShadow
      className={classNames.join(' ')}
      disabled={isDisabled}
      style={{
        paddingTop: '27px !Important',
        paddingBottom: '27px !Important',
        borderRadius: rounded ? '22px' : '8px',
        opacity: isDisabled ? '0.3' : '1',
        width: isBlock && '100%',
        color: 'dark',
      }}
      {...internalProps}
      {...rest}
    >
      <>
        {isValidElement(startIcon) &&
          cloneElement(startIcon, {
            // @ts-ignore
            mr: '0.5rem',
          })}
        {children}
        {isValidElement(endIcon) &&
          cloneElement(endIcon, {
            // @ts-ignore
            ml: '0.5rem',
          })}
      </>
    </StyledButton>
  );
};

Button.defaultProps = {
  isLoading: false,
  external: false,
  variant: variants.PRIMARY,
  scale: scales.MD,
  disabled: false,
};

export default Button;
