import { bsc } from './bsc'
import { bsctest } from './bscTest'

export const chainMap = {
  bsc,
  bsctest,
}
export const chains = Object.values(chainMap)

export const getChain = (chainId: number) => {
  return chains.find((chain) => chain.id === chainId)
}

export enum ChainId {
  BSC = bsc.id,
  TBSC = bsctest.id,
}
