import { ChainId } from '@pancakeswap/sdk'
import FarmsTbscPriceHelper from './97'

// todo: make dynamic
export const getFarmsPriceHelperLpFiles = (chainId: ChainId) => {
  switch (chainId) {
    case ChainId.TBSC:
      return FarmsTbscPriceHelper
    default:
      return []
  }
}
