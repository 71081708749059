import React, { SVGProps } from 'react'

export const HistoryIcon = ({ props }: { props?: SVGProps<any> }) => {
  return (
    <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width={48} height={48} rx={16} fill="#141414" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.929 29.657a1 1 0 000 1.414c3.905 3.905 10.237 3.905 14.142 0 3.905-3.905 3.905-10.237 0-14.142-3.905-3.905-10.237-3.905-14.142 0A9.962 9.962 0 0014.049 23h-1.05a1 1 0 00-.706 1.707l2 2 .002.002a.997.997 0 001.413-.003l2-1.999A1 1 0 0017 23h-.938a8 8 0 112.28 6.657 1 1 0 00-1.413 0zM21.999 20a1 1 0 112 0v4h4a1 1 0 010 2h-5a1 1 0 01-1-1v-5z"
        fill="#b4b4b4"
      />
    </svg>
  )
}
