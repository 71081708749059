import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="79" height="77" viewBox="0 0 79 77" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#filter0_f_9_2678)">
        <ellipse cx="39.5051" cy="38.5" rx="18.7033" ry="18.5" fill="#182325" />
      </g>
      <path
        d="M40.0112 30.5L40.0112 42.086L44.5607 37.586L45.9902 39L39.0003 45.914L32.0103 39L33.4398 37.586L37.9893 42.086L37.9893 30.5L40.0112 30.5Z"
        fill="#27D880"
      />
      <defs>
        <filter
          id="filter0_f_9_2678"
          x="0.801758"
          y="0"
          width="77.4062"
          height="77"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur_9_2678" />
        </filter>
      </defs>
    </Svg>
  );
};

export default Icon;
