import { BigNumber } from '@ethersproject/bignumber'
import { PoolCategory, SerializedPoolConfig } from './types'
import { bsctestTokens } from '@pancakeswap/tokens'

export const MAX_LOCK_DURATION = 31536000
export const UNLOCK_FREE_DURATION = 604800
export const ONE_WEEK_DEFAULT = 604800
export const BOOST_WEIGHT = BigNumber.from('20000000000000')
export const DURATION_FACTOR = BigNumber.from('31536000')

export const vaultPoolConfig = {} as const

export const livePools: SerializedPoolConfig[] = [
  {
    sousId: 1,
    stakingToken: bsctestTokens.cra,
    earningToken: bsctestTokens.cra,
    contractAddress: {
      97: '0x749a345EDe9AF9424d00B9c7447383767CFd0E48',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.009259',
    version: 2,
  },
  {
    sousId: 2,
    stakingToken: bsctestTokens.usdt,
    earningToken: bsctestTokens.cra,
    contractAddress: {
      97: '0xb57eD7B5316FeC12C8d3a791F5BB468e48e654AE',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.019259',
    version: 2,
  },
  {
    sousId: 3,
    stakingToken: bsctestTokens.wbnb,
    earningToken: bsctestTokens.cra,
    contractAddress: {
      97: '0xa150771a86d1d70A9F65fbEeBD8B11DD1CDcaaD0',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.009259',
    version: 2,
  },
].map((p) => ({
  ...p,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

// known finished pools
const finishedPools = [].map((p) => ({
  ...p,
  isFinished: true,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

export default [...livePools, ...finishedPools]
