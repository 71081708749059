import { ChainId, ERC20Token } from '@pancakeswap/sdk'
import { bscTokens } from './56'
import { bsctestTokens } from './97'

export const USD: Record<ChainId, ERC20Token> = {
  [ChainId.BSC]: bscTokens.usdt, // todo: add proper BSC USDT
  [ChainId.TBSC]: bsctestTokens.usdt, // todo: add proper BSC USDT
}

export const ICE: Record<ChainId, ERC20Token> = {
  [ChainId.BSC]: bscTokens.bnb, // todo: add proper BSC ICE
  [ChainId.TBSC]: bsctestTokens.cra, // todo: add proper BSC ICE
}
