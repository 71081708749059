import { ChainId } from '@pancakeswap/sdk'

export const STABLESWAP_SUBGRAPH_CLIENT = 'https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-stableswap'
export const GRAPH_HEALTH = 'https://the-graph-status.icecreamswap.com/graphql'

export const FARM_API = 'https://farms.pancake-swap.workers.dev'

export const BIT_QUERY = 'https://graphql.bitquery.io'

export const ACCESS_RISK_API = 'https://red.alert.pancakeswap.com/red-api'

export const CELER_API = 'https://api.celerscan.com/scan'
// TODO: network change graphQL connection
export const INFO_CLIENT_WITH_CHAIN = {
  [ChainId.TBSC]: 'https://subgraph-testnet.nusa.live/subgraphs/name/craswap/exchange-subhgraph-testnet',
  [ChainId.BSC]: 'https://proxy-worker-api.pancakeswap.com/bsc-exchange',
}

export const BLOCKS_CLIENT_WITH_CHAIN: Partial<Record<ChainId, string>> = {
  [ChainId.TBSC]: 'https://subgraph-testnet.nusa.live/subgraphs/name/craswap/blocks-bsc-testnet',
  [ChainId.BSC]: 'https://api.thegraph.com/subgraphs/name/pancakeswap/blocks',
}

export const INFO_CLIENT_TBSC = INFO_CLIENT_WITH_CHAIN[ChainId.TBSC]
export const BLOCKS_CLIENT_TBSC = BLOCKS_CLIENT_WITH_CHAIN[ChainId.TBSC]

export const INFO_CLIENT_BSC = INFO_CLIENT_WITH_CHAIN[ChainId.BSC]
export const BLOCKS_CLIENT_BSC = BLOCKS_CLIENT_WITH_CHAIN[ChainId.BSC]
