import React, { SVGProps } from 'react'

export const SettingIcon = ({ props }: { props?: SVGProps<any> }) => {
  return (
    <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width={48} height={48} rx={16} fill="#141414" />
      <path
        d="M34.5 18h-3.075c-.375-1.725-1.875-3-3.675-3-1.8 0-3.3 1.275-3.675 3H13.5v1.5h10.575c.375 1.725 1.875 3 3.675 3 1.8 0 3.3-1.275 3.675-3H34.5V18zm-6.75 3c-1.275 0-2.25-.975-2.25-2.25s.975-2.25 2.25-2.25 2.25.975 2.25 2.25S29.025 21 27.75 21zM13.5 30h3.075c.375 1.725 1.875 3 3.675 3 1.8 0 3.3-1.275 3.675-3H34.5v-1.5H23.925c-.375-1.725-1.875-3-3.675-3-1.8 0-3.3 1.275-3.675 3H13.5V30zm6.75-3c1.275 0 2.25.975 2.25 2.25s-.975 2.25-2.25 2.25S18 30.525 18 29.25 18.975 27 20.25 27z"
        fill="#b4b4b4"
      />
    </svg>
  )
}
