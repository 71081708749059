import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg
      style={{
        height: "24px",
        width: "100%",
        top: 2,
      }}
      viewBox="0 0 20 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.278 11.226v0h.006a1.148 1.148 0 01.796.313 1.029 1.029 0 01.324.753v8.643c0 .139-.028.277-.083.406a1.06 1.06 0 01-.24.346l.346.36-.346-.36c-.104.1-.228.18-.365.234l.183.465-.183-.465a1.146 1.146 0 01-.432.08v0h-.81c-.298 0-.582-.116-.789-.318a1.045 1.045 0 01-.32-.748v-8.643a1.062 1.062 0 01.32-.748c.103-.1.225-.18.36-.235.136-.055.282-.083.43-.083h.803zm5.666 1.702h0c.229 0 .508.14.769.38.202.186.321.437.34.7v6.79c0 .279-.114.548-.32.75l.348.358-.348-.359c-.207.202-.49.317-.79.317h-.809a1.148 1.148 0 01-.797-.313 1.065 1.065 0 01-.24-.346 1.03 1.03 0 01-.084-.406v0-6.806 0a1.03 1.03 0 01.324-.753 1.117 1.117 0 01.797-.312v0h.81zM3.025 14.917l-.349-.358.35.358a1.15 1.15 0 01.8-.322h.805c.303 0 .59.117.8.322l.35-.358-.35.358c.21.204.326.478.326.76v5.255c-.004.28-.12.55-.33.75l.347.36-.346-.36A1.15 1.15 0 014.63 22h-.805c-.3 0-.587-.115-.797-.317l-.346.36.346-.36a1.062 1.062 0 01-.329-.751v-5.255c0-.282.115-.556.325-.76zm18.067-5.154v.085h.5c.3 0 .587.116.797.318.208.2.325.47.329.75v9.93c-.004.28-.12.55-.33.752a1.15 1.15 0 01-.796.317h-.805c-.3 0-.587-.116-.797-.317a1.062 1.062 0 01-.329-.751V10.83c.004-.28.12-.55.33-.75a1.15 1.15 0 01.796-.318h.305zM4.228 11.638h-.3a.781.781 0 01-.431-.443.744.744 0 01-.024-.456.772.772 0 01.254-.394h0l.005-.005L9.34 5.53a.86.86 0 01.922-.092l4.93 2.518.272.138.248-.177 4.981-3.576a.842.842 0 01.57-.093c.202.038.379.148.498.304a.751.751 0 01-.096 1.019l-5.645 4.051h0l-.005.004a.806.806 0 01-.85.062h0l-.007-.003-4.86-2.484-.296-.15-.254.213-4.981 4.183a.85.85 0 01-.538.191z"
        stroke="url(#paint0_linear_994_29123)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_994_29123"
          x1={34.2444}
          y1={-14.5318}
          x2={-7.16123}
          y2={-11.6862}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDE53A" />
          <stop offset={1} stopColor="#FDE53A" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Icon;
