import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="48" height="48" rx="16" fill="#141414" />
      <g>
        <path
          d="M27 30C27 30.852 26.014 31.297 25.377 30.783L25.293 30.707L19.293 24.707C19.1208 24.5348 19.0174 24.3057 19.0021 24.0626C18.9868 23.8196 19.0607 23.5794 19.21 23.387L19.293 23.293L25.293 17.293L25.387 17.21L25.464 17.156L25.56 17.102L25.596 17.085L25.663 17.058L25.771 17.026L25.824 17.016L25.884 17.006L25.941 17.002H26.059L26.117 17.007L26.177 17.016L26.229 17.026L26.337 17.058L26.404 17.085L26.536 17.155L26.626 17.22L26.707 17.293L26.79 17.387L26.844 17.464L26.898 17.56L26.915 17.596L26.942 17.663L26.974 17.771L26.984 17.824L26.994 17.884L26.998 17.941L27 30Z"
          fill="#b4b4b4"
        />
      </g>
      <defs>
        <clipPath id="clip0_26_4215">
          <rect width="24" height="24" fill="white" transform="matrix(0 1 -1 0 36 12)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
